import React, { useRef, useEffect } from "react";
import { useLocation } from "@reach/router";

// Components
import SecondaryLanding from "../layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import BestBanksDefault from "../components/best-banks/best-banks-default";
import Icon from "../components/custom-widgets/icon";
import useCheckMktoFormIsSubmitted from "../hooks/use-check-mkto-form-is-submitted";

const EventRegistration = () => {
  const title = "WaFd Bank Presents";
  const description = "WaFd Bank & Capital Economics - Trump's Second Term: Implications for the US Economy";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/event-registration"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: "true",
      title: "Event Registration"
    }
  ];

  const isMktoFormSubmitted = useCheckMktoFormIsSubmitted();

  const mkto = useRef(null);
  let pageLocation = useLocation();
  let hash = useLocation().hash;

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://app-sj21.marketo.com/js/forms2/js/forms2.min.js";
    scriptTag.id = "mkto-script";
    scriptTag.async = true;

    if (typeof window !== "undefined") {
      const formRequestSent = localStorage.getItem("formRequestSent");
      if (formRequestSent) {
        let pageOrigin = pageLocation.origin;
        // console.log("ORIGIN:", pageOrigin);

        let pagePathname = pageLocation.pathname;
        // console.log("PATHNAME:", pagePathname);

        let cleanedLink = `${pageOrigin}${pagePathname}${hash}`;
        // console.log("CLEANED LINK:", cleanedLink);
        window.history.replaceState(null, "", cleanedLink);
        window.location.href = cleanedLink;
        localStorage.removeItem("formRequestSent");
      }

      if (isMktoFormSubmitted && !formRequestSent) {
        // console.log("FORM SUBMITTED");
        localStorage.setItem("formRequestSent", "true");
      }

      if (!document.getElementById("mkto-script")) {
        mkto?.current?.appendChild(scriptTag);
        const checkInterval = setInterval(() => {
          if (typeof MktoForms2 !== "undefined") {
            MktoForms2.loadForm("//316-DXP-546.mktoweb.com", "316-DXP-546", 1484);
            clearInterval(checkInterval);
          } else {
          }
        }, 500);
      }
    }
  }, [hash, isMktoFormSubmitted]);

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WaFd Bank Presents</h1>
        <h2 className="text-success">
          Thank you for your interest in our special online event: WaFd Bank & Capital Economics - Trump's Second Term:
          Implications for the US Economy
        </h2>
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <h3>
              Tuesday, November 26th, 2024
              <br />
              9:00AM - 9:30AM PST
            </h3>
            {isMktoFormSubmitted ? (
              <>
                <h3 className="font-weight-bold">
                  <Icon name="check-circle" lib="fas" class="mr-2 text-success" />
                  Success
                </h3>
                <p>Thank you for registering.</p>
              </>
            ) : (
              <>
                <div ref={mkto} id="register-wafd-event-form">
                  <h3 className="font-weight-bold mb-0">Register</h3>
                  <form id="mktoForm_1484"></form>
                </div>
              </>
            )}
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-auto">
                <img
                  src="/images/banker-headshots/paul-ashworth.jpg"
                  alt="Paul Ashworth - Capital Economics Chief North America Economist."
                />
              </div>
              <div className="col">
                <p>
                  Paul Ashworth is Capital Economics Chief North America Economist, with overall responsibility for
                  coverage of the US and Canada. He joined Capital Economics in 2001 and has led the Toronto office
                  since 2007. Paul joined Capital Economics from the National Institute of Economic and Social Research
                  (NIESR) where he worked on their large-scale model of the global economy and was responsible for
                  coverage of various countries, including Canada and Germany. He holds degrees in Mathematics and
                  Economics from Strathclyde and Warwick Universities, and his PhD thesis focused on asymmetry and
                  asymmetric adjustment in macroeconomics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default EventRegistration;
